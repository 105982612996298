import { createContext, ReactNode, useContext, useState, useEffect } from "react";

export const MiniPlayerProviderContext = createContext<MiniPLayerApi | undefined>(undefined);

interface MiniPLayerApi {
  miniplayer: boolean;
  setMiniplayer: React.Dispatch<React.SetStateAction<boolean>>;
}
interface Props {
  children: ReactNode;
}

export default function MiniPlayerProvider({ children }: Props) {
  const [miniplayer, setMiniplayer] = useState(false);

  const api = {
    miniplayer,
    setMiniplayer
  };
  return <MiniPlayerProviderContext.Provider value={api}>{children}</MiniPlayerProviderContext.Provider>;
}

export function useMiniplayer() {
  const context = useContext(MiniPlayerProviderContext);
  if (!context) {
    throw new Error("Use inside MiniPlayerProviderContext only");
  }

  return context;
}

export function useSetMiniplayer(value = true, timeout = 0) {
  const { setMiniplayer } = useMiniplayer();
  useEffect(() => {
    const id = setTimeout(() => {
      setMiniplayer(value);
    }, timeout);
    return () => {
      clearTimeout(id);
    };
  }, [value, setMiniplayer, timeout]);
}
