import { ReactNode } from "react";
import styled from "styled-components";
import Console from "./Console";
import BearlyBass from "../bearlybass.png";
import { useMiniplayer } from "../MiniPlayerProvider";
interface Props {
  children: ReactNode;
}
const Root = styled.div<{ $miniplayer: boolean }>`
  display: flex;
  width: 100%;
  margin: 0 auto;

  height: calc(
    100% -
      ${({ theme, $miniplayer }) =>
        $miniplayer ? theme.audioPlayer.controls.height.xsMiniPlayer : theme.audioPlayer.controls.height.xs}px
  );

  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    height: calc(100% - ${({ theme }) => theme.audioPlayer.controls.height.sm}px);
  }
`;
const LoopContent = styled.div`
  position: relative;
  width: 100%;
  min-height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80px;
  background-image: url(${BearlyBass});

  background-position: center 60%;
  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    background-position: center;
  }
`;

function LoopNavigator({ children }: Props) {
  const { miniplayer } = useMiniplayer();
  return <Root $miniplayer={miniplayer}>{children}</Root>;
}
LoopNavigator.LoopContent = LoopContent;
LoopNavigator.Console = Console;

export default LoopNavigator;
