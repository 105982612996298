import { Container, Link } from "./global.sc";
import { useSetMiniplayer } from "./MiniPlayerProvider";
import BearlyBass from "./bearlybass.png";
import useAudioPlayer from "./AudioPlayer/useAudioPlayer";

function AboutRoute() {
  useSetMiniplayer(true);
  const { linkToCurrentLoop } = useAudioPlayer();
  return (
    <Container small>
      <article>
        <h3>About</h3>
        <img alt="Tony" style={{ height: "128px", width: "auto" }} src={BearlyBass} />
        <p>
          Bearly Bass is creating, collecting, categorising and distributing high quality and real played audio loops,
          currently bassloops only. Here you find a rich pool of loops, free to use and download for music producers,
          musicians or everybody else.
        </p>
        <Link to={linkToCurrentLoop ? linkToCurrentLoop : "/"}>Go back</Link>
      </article>
    </Container>
  );
}

export default AboutRoute;
