import { useCallback, useContext, useMemo, useState } from "react";
import useAnimationFrame from "../useAnimationFrame";
import { AudioplayerProviderContext } from "./AudioPlayerProvider";

export default function useCurrentCompleteness(): number | undefined {
  const context = useContext(AudioplayerProviderContext);
  if (!context) {
    throw new Error("No Audioplayer Context");
  }

  const getCompleteness = useMemo(() => context.getCompleteness, [context]);

  const [percent, setPercent] = useState<number | undefined>(undefined);

  const callback = useCallback(() => {
    setPercent(getCompleteness());
  }, [getCompleteness]);

  useAnimationFrame(callback);
  return percent;
}
