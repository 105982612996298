import { Tag, Taxonomy } from "./loopData/loops";
import styled, { css } from "styled-components";
import { LoopTagListVariant } from "./LoopTagList";

interface Props {
  children: Tag;
  onClick: () => void;
  variant?: LoopTagListVariant;
}

const Root = styled.button<{ taxonomy: Taxonomy; variant?: LoopTagListVariant }>`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  padding: 6px 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 80%;
  display: inline-block;
  color: ${({ theme }) => theme.palette.text.contrast};
  cursor: pointer;
  text-transform: uppercase;
  background-color: ${({ theme, taxonomy }) => theme.tags[taxonomy].color};
  ${({ variant }) =>
    variant &&
    css`
      position: relative;
      overflow: hidden;
      &:before {
        transition: all ease-out 0.2s 0;
        content: '-';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: ${({ theme }) => theme.palette.text.contrast};
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        color: $black;
        margin: auto;
        top: 0;
        right:0;
        bottom:0;
        left: 0;
        font-size: 15px;
        color: ${({ theme }) => theme.palette.text.main};
        // padding:2px 0;
        opacity: 0;

      }
      &:hover {
        background-color: ${({ theme }) => theme.palette.background.default};
        text-decoration: none;
        color: ${({ theme }) => theme.palette.text.main};
        &:before {
          opacity: 1;
          transition: all ease-out 0.2s 0.2s;
        }
      }
    }`}

  ${({ variant }) =>
    variant === "selectable" &&
    css`
    &:hover {
        &:before {
          content: '+';
          line-height:1.1;
          background-color: green;
        }
      }
    }`}
  
    ${({ variant }) =>
    variant === "removeable" &&
    css`
    &:hover {
        &:before {
          content: '-';
          line-height:1;
          background-color: red;
        }
      }
    }`}
`;

function LoopTag({ onClick, children: tag, variant }: Props) {
  const formattedName = tag.taxonomy === "tempo" ? `${tag.name} bmp` : tag.name;
  return (
    <Root onClick={onClick} taxonomy={tag.taxonomy} variant={variant}>
      {formattedName}
    </Root>
  );
}

export default LoopTag;
