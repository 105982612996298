// https://usehooks.com/usePrevious/

import { useEffect, useRef } from "react";

export function usePrevious<T extends any>(value: T): T | undefined {
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
