import Icon from "../Icon";
import useAudioPlayer from "./useAudioPlayer";
import styled from "styled-components";
import { resetFieldset, resetButton, SrOnly } from "../global.sc";
import { useMiniplayer } from "../MiniPlayerProvider";
const BUTTON_SIZE = 52;

const Root = styled.div<{ $miniplayer: boolean }>`
  position: absolute;
  height: ${({ theme, $miniplayer }) =>
    $miniplayer ? theme.audioPlayer.controls.height.xsMiniPlayer : theme.audioPlayer.controls.height.xs}px;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  padding: 6px;
  button {
    ${resetButton}

    outline: 0;
  }
  label {
  }
  input[type="checkbox"],
  input[type="radio"],
  button {
    padding: 4px 12px;
    position: relative;
    top: 2px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label:before,
  input[type="checkbox"] + label:before {
    display: inline-block;
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label,
  button {
    display: inline-block;
    padding: 0 6px;
    cursor: pointer;
  }

  button.noIcon {
    font-size: 24px;
    line-height: ${BUTTON_SIZE}px;
    vertical-align: bottom;
  }

  input[type="radio"] + label:before {
    margin-left: 6px;
    margin-right: 6px;
  }

  #checkbox__playPause + label:before {
    position: relative;
    top: 2px;
    margin-left: 8px;
  }

  // compare: https://codepen.io/seansean11/pen/dhwzj
  @keyframes pulse {
    30% {
      box-shadow: 0 0 0 50px
        rgba(
          ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
          ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
          ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
          0
        );
    }
    100% {
      box-shadow: 0 0 0 0
        rgba(
          ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
          ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
          ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
          0
        );
    }
  }

  #checkbox__playPause.neverPlayed + label {
    box-shadow: 0 0 0 0
      rgba(
        ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
        ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
        ${({ theme }) => theme.audioPlayer.controls.pulseShadowDepth},
        0.5
      );
    border-radius: 20px;
    animation: pulse 3.2s infinite;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    text-align: left;
    height: ${({ theme }) => theme.audioPlayer.controls.height.sm}px;
    fieldset:first-of-type {
      float: left;
    }
    fieldset:last-of-type {
      display: inline;
      float: left;
    }
  }
`;

const FieldSet = styled.fieldset<{ $miniplayer?: boolean }>`
  display: ${({ $miniplayer }) => ($miniplayer ? "none" : "inline")};
  ${resetFieldset}

  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    display: inline;
  }
`;

const Divider = styled.span`
  border-left: 1px solid ${({ theme }) => theme.palette.gray.light};
  margin: 6px 10px 6px 16px;
  height: 70%;
  float: left;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    display: inline;
  }
`;

export function Controls() {
  const {
    previous,
    next,
    playerState,
    userDidNotPressPlayYet,
    toggleRepeat,
    playmode,
    setPlaymode,
    togglePlay,
    toggleSpeed
  } = useAudioPlayer();
  const { miniplayer } = useMiniplayer();

  return (
    <Root $miniplayer={miniplayer}>
      <>
        <FieldSet $miniplayer={miniplayer}>
          <input
            id="radio__playSolo"
            type="radio"
            onChange={() => {
              setPlaymode("solo");
            }}
            value="solo"
            checked={playmode === "solo"}
          />
          <label htmlFor="radio__playSolo">
            <SrOnly>Play bass only</SrOnly>
            <Icon
              symbol={playmode === "solo" ? "guitar-negative" : "guitar"}
              size={BUTTON_SIZE}
              variant={playmode === "solo" ? "primary" : "default"}
            />
          </label>
          <input
            id="radio__playFull"
            type="radio"
            onChange={() => {
              setPlaymode("full");
            }}
            value="full"
            checked={playmode === "full"}
          />
          <label htmlFor="radio__playFull">
            <SrOnly>Play with band</SrOnly>
            <Icon
              symbol={playmode === "full" ? "drums-negative" : "drums"}
              size={BUTTON_SIZE}
              variant={playmode === "full" ? "primary" : "default"}
            />
          </label>
          <input
            id="radio__playAll"
            type="radio"
            onChange={() => {
              setPlaymode("all");
            }}
            value="all"
            checked={playmode === "all"}
          />
          <label htmlFor="radio__playAll">
            <SrOnly>Play complete distro (all keys)</SrOnly>
            <Icon
              symbol={playmode === "all" ? "musicrecord-negative" : "musicrecord"}
              size={BUTTON_SIZE}
              variant={playmode === "all" ? "primary" : "default"}
            />
          </label>
        </FieldSet>
        <Divider />
      </>
      <FieldSet>
        <input
          id="checkbox__playPause"
          type="checkbox"
          className={userDidNotPressPlayYet ? "neverPlayed" : undefined}
          checked={playerState?.playing ?? false}
          onChange={togglePlay}
        />
        <label htmlFor="checkbox__playPause">
          <SrOnly>Play / Pause</SrOnly>
          <Icon symbol={playerState?.playing ? "pause" : "play"} size={BUTTON_SIZE} />
        </label>

        <button onClick={previous}>
          <SrOnly>Backward</SrOnly>
          <Icon symbol="rewind" size={BUTTON_SIZE} />
        </button>
        <button onClick={next}>
          <SrOnly>Forward</SrOnly>
          <Icon symbol="forward" size={BUTTON_SIZE} />
        </button>

        <input id="checkbox__looping" type="checkbox" checked={playerState?.repeat ?? false} onChange={toggleRepeat} />
        <label htmlFor="checkbox__looping">
          <SrOnly> {playerState?.repeat ? "Looping On" : "Looping Off"}</SrOnly>
          <Icon symbol="repeat" size={BUTTON_SIZE} variant={playerState?.repeat ? "primary" : "default"} />
        </label>
        <button className="noIcon" onClick={toggleSpeed}>
          {playerState?.speed}x
        </button>
      </FieldSet>
    </Root>
  );
}
