import { useState } from "react";
import styled from "styled-components";
import { LoopName } from "./AudioPlayer/types";
import { SrOnly } from "./global.sc";
import Icon from "./Icon";
import { Loop } from "./loopData/loops";

type Format = "rx2" | "wav" | "aif";
interface Props {
  loop: Loop;
  format: Format;
}

const Root = styled.a`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.text.main};
`;

const DownloadIcon = styled(Icon)``;
const makeDownloadUrl = (loopName: LoopName) => (format: Format) =>
  `${process.env.REACT_APP_LOOP_CONTENT_URL}/download/zip/BEARLY BASS - ${loopName} -${format.toUpperCase()}.zip`;

function DownloadLink({ format, loop }: Props) {
  const [hovered, setHovered] = useState(false);
  const downloadUrl = makeDownloadUrl(loop.name)(format);
  return (
    <Root
      target="_self"
      download
      href={downloadUrl}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <DownloadIcon symbol={hovered ? "cloud-negative" : "cloud"} size={36} />
      <SrOnly />
    </Root>
  );
}

export default DownloadLink;
