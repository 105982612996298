import { RouteComponentProps } from "react-router";
import { LoopName } from "./AudioPlayer/types";
import ExploredLoop from "./ExploredLoop";
import useLoops from "./useLoops";
import styled from "styled-components";
import { TransitionStatus } from "react-transition-group";
import useAudioPlayer from "./AudioPlayer/useAudioPlayer";
import { Direction } from "./AudioPlayer/AudioPlayerProvider";
import { useSetMiniplayer } from "./MiniPlayerProvider";
import { useDocumentTitle } from "./useDocumentTitle";

function resolveTranslateX({
  transitionStatus,
  direction
}: {
  transitionStatus: TransitionStatus;
  direction: Direction;
}): number {
  switch (transitionStatus) {
    case "entering":
      return direction === "forward" ? 100 : -100;

    case "exiting":
      return direction === "forward" ? -100 : 100;

    default:
      return 0;
  }
}
const Root = styled.div<{ transitionStatus: TransitionStatus; direction: Direction }>`
  transition: all ease-out 0.5s;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateX(${resolveTranslateX}vw);
`;

function ExploredLoopRoute({
  match,
  transitionStatus
}: RouteComponentProps<{ loopName: LoopName }> & { transitionStatus: TransitionStatus }) {
  const { loopForSlug } = useLoops();
  const { direction } = useAudioPlayer();
  const loop = loopForSlug(match.params.loopName);
  useDocumentTitle(loop?.title);

  useSetMiniplayer(false, 300);

  if (!loop) {
    return null;
  }

  return (
    <Root transitionStatus={transitionStatus} direction={direction}>
      <ExploredLoop loop={loop} />
    </Root>
  );
}

export default ExploredLoopRoute;
