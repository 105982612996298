import styled from "styled-components";

export const Site = styled.div`
  color: ${({ theme }) => theme.palette.text.main};
  background: ${({ theme }) => theme.palette.background.default};
  padding: 0;
  height: 100%;
`;

export const Main = styled.main`
  height: calc(100% - ${({ theme }) => theme.header.height}px);
  overflow: hidden;
`;
