import { useContext } from "react";
import { AudioplayerProviderContext } from "./AudioPlayerProvider";

export default function useAudioPlayer() {
  const context = useContext(AudioplayerProviderContext);
  if (!context) {
    throw new Error("No Audioplayer Context");
  }
  const { getCompleteness, ...publicApi } = context;
  return publicApi;
}
