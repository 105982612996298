import { LoopName } from "./AudioPlayer/types";
import { useCallback, useMemo } from "react";
import { Loop, loops as allLoops } from "./loopData/loops";
import useTags from "./useTags";

interface LoopsApi {
  loops: Loop[];
  loopForSlug: (loopName: LoopName) => Loop | undefined;
}

const shuffleArray = <T extends any[]>(arr: T) => arr.sort(() => Math.random() - 0.5);

export default function useLoops(): LoopsApi {
  const { selectedMap } = useTags();

  const loops = useMemo(
    () =>
      shuffleArray([...allLoops]).filter((loop) => {
        for (const [key, value] of Object.entries(selectedMap)) {
          if (value) {
            const intersection = loop.tags
              .filter((c) => c.taxonomy === key)
              .map((c) => c.id)
              .filter((c) => value.includes(c));
            if (!intersection.length) {
              return false;
            }
          }
        }
        return true;
      }),
    [selectedMap]
  );

  const loopForSlug = useCallback((loopName: LoopName) => loops.find((c) => c.name === loopName), [loops]);
  return { loops, loopForSlug };
}
