import { Tag } from "./loopData/loops";
import styled, { css } from "styled-components";
import LoopTag from "./LoopTag";

interface Props {
  children: Tag[];
}

const Root = styled.div`
  width: 100%;
`;
const List = styled.ul<{ spaceBetween: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  width: auto;
  display: flex;
  flex-wrap: wrap;

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;
const ListItem = styled.li`
  white-space: nowrap;
  display: inline-block;
  transition: all ease-out 0.5s;
  margin-bottom: 6px;
`;

export type LoopTagListVariant = "selectable" | "removeable";

interface Props {
  children: Tag[];
  onClick: (tag: Tag) => void;
  variant?: LoopTagListVariant;
  className?: string;
  spaceBetweenTags?: boolean;
}
function LoopTagList({ onClick, children: tags, className, variant = "selectable", spaceBetweenTags = false }: Props) {
  return (
    <Root className={className}>
      <List spaceBetween={spaceBetweenTags}>
        {tags.map((c) => (
          <ListItem key={`${c.taxonomy}${c.id}`}>
            <LoopTag onClick={() => onClick(c)} variant={variant}>
              {c}
            </LoopTag>
          </ListItem>
        ))}
      </List>
    </Root>
  );
}

export default LoopTagList;
