import styled from "styled-components";
import { Loop, Tag } from "./loopData/loops";
import useTags from "./useTags";
import ExploredLoopTitle from "./ExploredLoopTitle";
import LoopTagList from "./LoopTagList";
import useAudioPlayer from "./AudioPlayer/useAudioPlayer";
import { useCallback } from "react";
import { Error } from "./global.sc";

interface Props {
  loop: Loop;
}

const Root = styled.div`
  background: ${({ theme }) => theme.exploredLoop.background};
  width: 320px;
  margin: 0 auto;
  padding: 16px;
  min-height: ${({ theme }) => theme.exploredLoop.height};
  padding-top: 80px; // to make space for loop-filter */
  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    padding-top: 16px;
  }
`;

const LoopTagsArea = styled.div``;

function ExploredLoop({ loop: { title, tags, name } }: Props) {
  const { addTag } = useTags();
  const { play, playerState } = useAudioPlayer();
  const playing = playerState?.playing ?? false;
  const loadingError = playerState?.currentTrack?.slug === name && playerState.loadErrors.includes(name);
  const playingError = playerState?.currentTrack?.slug === name && playerState.playErrors.includes(name);

  const handelClickTag = useCallback(
    (tag: Tag) => {
      if (!playing) {
        play();
      }
      addTag(tag);
    },
    [playing, play, addTag]
  );
  return (
    <Root>
      {loadingError && <Error>Error: Audio File could not be loaded.</Error>}
      {playingError && <Error>Error: Audio File could not be played.</Error>}

      <ExploredLoopTitle>{title}</ExploredLoopTitle>
      <LoopTagsArea>
        <LoopTagList onClick={handelClickTag} variant="selectable" spaceBetweenTags>
          {tags}
        </LoopTagList>
      </LoopTagsArea>
    </Root>
  );
}

export default ExploredLoop;
