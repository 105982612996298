export interface Loop {
  id: number;
  title: string;
  name: string;
  tags: Tag[];
}

export interface Tag {
  id: number;
  name: string;
  taxonomy: Taxonomy;
}
export type Taxonomy = "groove" | "progression" | "scale" | "styles" | "technique" | "tempo" | "available";

export const loops: Loop[] = [
  {
    id: 667,
    title: "planky toy",
    name: "planky-toy",
    tags: [
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 263,
        name: "only 5+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 666,
    title: "worldshag style",
    name: "worldshag-style",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 247,
        name: "9/min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 665,
    title: "pawn whoo",
    name: "pawn-whoo",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 263,
        name: "only 5+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 664,
    title: "terroine polly",
    name: "terroine-polly",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 288,
        name: "wah wah",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      },
      {
        id: 1000,
        name: "superrare.co",
        taxonomy: "available"
      }
    ]
  },
  {
    id: 663,
    title: "wembley tune",
    name: "wembley-tune",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 662,
    title: "perlcuna dam",
    name: "perlcuna-dam",
    tags: [
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 247,
        name: "9/min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 661,
    title: "tabtale peanut",
    name: "tabtale-peanut",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 660,
    title: "clastic draft",
    name: "clastic-draft",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      },
      {
        id: 1000,
        name: "superrare.co",
        taxonomy: "available"
      }
    ]
  },
  {
    id: 659,
    title: "cort amber",
    name: "cort-amber",
    tags: [
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 658,
    title: "trichet mania",
    name: "trichet-mania",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 657,
    title: "carlo partivo",
    name: "carlo-partivo",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 656,
    title: "cadilac combown",
    name: "cadilac-combown",
    tags: [
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      },
      {
        id: 1000,
        name: "superrare.co",
        taxonomy: "available"
      }
    ]
  },
  {
    id: 655,
    title: "fancy wale",
    name: "fancy-wale",
    tags: [
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 288,
        name: "wah wah",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 654,
    title: "persic wall",
    name: "persic-wall",
    tags: [
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 653,
    title: "shockseek chinch",
    name: "shockseek-chinch",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 652,
    title: "peel arabiator",
    name: "peel-arabiator",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 651,
    title: "presonic calypso",
    name: "presonic-calypso",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 323,
        name: "funky house",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 247,
        name: "9/min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 322,
        name: "125",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 650,
    title: "westham touch",
    name: "westham-touch",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 649,
    title: "town rack",
    name: "town-rack",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 648,
    title: "albride ann",
    name: "albride-ann",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 647,
    title: "shameless tounge",
    name: "shameless-tounge",
    tags: [
      {
        id: 302,
        name: "basic",
        taxonomy: "styles"
      },
      {
        id: 312,
        name: "behind the pulse",
        taxonomy: "styles"
      },
      {
        id: 313,
        name: "hip hop",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 646,
    title: "terry battle",
    name: "terry-battle",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 645,
    title: "key astone",
    name: "key-astone",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 263,
        name: "only 5+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 644,
    title: "pakewine trench",
    name: "pakewine-trench",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 321,
        name: "rock",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 643,
    title: "wonder ale",
    name: "wonder-ale",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 321,
        name: "rock",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 317,
        name: "aeolian",
        taxonomy: "scale"
      },
      {
        id: 318,
        name: "4 chords",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 642,
    title: "curve spoon",
    name: "curve-spoon",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 641,
    title: "backyard fetch",
    name: "backyard-fetch",
    tags: [
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 640,
    title: "sinky slur",
    name: "sinky-slur",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 310,
        name: "oldschool",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 639,
    title: "down and pawned",
    name: "down-and-pawned",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 638,
    title: "trimatic pool",
    name: "trimatic-pool",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 637,
    title: "sally sleeve",
    name: "sally-sleeve",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 636,
    title: "stanley ork",
    name: "stanley-ork",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 635,
    title: "cloud shak",
    name: "cloud-shak",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 310,
        name: "oldschool",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 634,
    title: "tiny tone",
    name: "tiny-tone",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 633,
    title: "pablo mic",
    name: "pablo-mic",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 308,
        name: "2 chords",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 632,
    title: "tooth stick",
    name: "tooth-stick",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 321,
        name: "rock",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 631,
    title: "transaction break",
    name: "transaction-break",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 321,
        name: "rock",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 630,
    title: "curley habbit",
    name: "curley-habbit",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 629,
    title: "doghnut park",
    name: "doghnut-park",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 628,
    title: "thinking squeeze",
    name: "thinking-squeeze",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 627,
    title: "maxx",
    name: "maxx",
    tags: [
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 626,
    title: "growling kazoo",
    name: "growling-kazoo",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 625,
    title: "xenias call",
    name: "xenias-call",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 247,
        name: "9/min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 624,
    title: "bridget garden",
    name: "bridget-garden",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 623,
    title: "pastime polonaise",
    name: "pastime-polonaise",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 310,
        name: "oldschool",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 622,
    title: "ataxic toe",
    name: "ataxic-toe",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 321,
        name: "rock",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 621,
    title: "bearly beast",
    name: "bearly-beast",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 620,
    title: "ping pawn",
    name: "ping-pawn",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 303,
        name: "dorian",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 619,
    title: "hardby sealing",
    name: "hardby-sealing",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 618,
    title: "turtle squash",
    name: "turtle-squash",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 321,
        name: "rock",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 617,
    title: "holy creep",
    name: "holy-creep",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 616,
    title: "didndt cha know",
    name: "didndt-cha-know",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 615,
    title: "perly wild",
    name: "perly-wild",
    tags: [
      {
        id: 320,
        name: "latin",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 319,
        name: "harmonic-minor",
        taxonomy: "scale"
      },
      {
        id: 318,
        name: "4 chords",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 614,
    title: "shoutn in",
    name: "shoutn-in",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 613,
    title: "dogmaniac",
    name: "dogmaniac",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 612,
    title: "sherly crue 2",
    name: "sherly-crue-2",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 317,
        name: "aeolian",
        taxonomy: "scale"
      },
      {
        id: 316,
        name: "3 chords",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 611,
    title: "sherly crue",
    name: "sherly-crue",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 610,
    title: "wilkens ore",
    name: "wilkens-ore",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 315,
        name: "110",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 609,
    title: "portland",
    name: "portland",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 608,
    title: "chasing wild 2",
    name: "chasing-wild-2",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 607,
    title: "chasing wild",
    name: "chasing-wild",
    tags: [
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 272,
        name: "min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 606,
    title: "pounding out",
    name: "pounding-out",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 605,
    title: "treat me bad 2",
    name: "treat-me-bad-2",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 604,
    title: "treat me bad",
    name: "treat-me-bad",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 301,
        name: "thumb",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 603,
    title: "whiling punsh",
    name: "whiling-punsh",
    tags: [
      {
        id: 314,
        name: "dreamy",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 602,
    title: "plaxe in doom 2",
    name: "plaxe-in-doom-2",
    tags: [
      {
        id: 313,
        name: "hip hop",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 601,
    title: "plaxe in doom",
    name: "plaxe-in-doom",
    tags: [
      {
        id: 312,
        name: "behind the pulse",
        taxonomy: "styles"
      },
      {
        id: 313,
        name: "hip hop",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 600,
    title: "shiny groom",
    name: "shiny-groom",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 599,
    title: "tiny mood",
    name: "tiny-mood",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 598,
    title: "tell me what",
    name: "tell-me-what",
    tags: [
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 597,
    title: "morly bunch",
    name: "morly-bunch",
    tags: [
      {
        id: 311,
        name: "chordal",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 596,
    title: "sun beem",
    name: "sun-beem",
    tags: [
      {
        id: 302,
        name: "basic",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 308,
        name: "2 chords",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 595,
    title: "down the hall",
    name: "down-the-hall",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 594,
    title: "brave moon 2",
    name: "brave-moon-2",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 593,
    title: "brave moon",
    name: "brave-moon",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 267,
        name: "min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 592,
    title: "turnin doze",
    name: "turnin-doze",
    tags: [
      {
        id: 310,
        name: "oldschool",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 591,
    title: "pale out",
    name: "pale-out",
    tags: [
      {
        id: 302,
        name: "basic",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 590,
    title: "ngi brooker",
    name: "ngi-brooker",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 589,
    title: "table cartoon",
    name: "table-cartoon",
    tags: [
      {
        id: 302,
        name: "basic",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 588,
    title: "grind wash",
    name: "grind-wash",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 310,
        name: "oldschool",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 266,
        name: "7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 587,
    title: "tailer brand",
    name: "tailer-brand",
    tags: [
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 308,
        name: "2 chords",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 586,
    title: "ham speck",
    name: "ham-speck",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 585,
    title: "kerosine boy",
    name: "kerosine-boy",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 309,
        name: "dirty",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 263,
        name: "only 5+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 584,
    title: "bone to close",
    name: "bone-to-close",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 308,
        name: "2 chords",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 307,
        name: "90",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 583,
    title: "drying king",
    name: "drying-king",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 305,
        name: "minimal",
        taxonomy: "styles"
      },
      {
        id: 306,
        name: "syncoped",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 582,
    title: "control obscure",
    name: "control-obscure",
    tags: [
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 247,
        name: "9/min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 581,
    title: "nature pan",
    name: "nature-pan",
    tags: [
      {
        id: 302,
        name: "basic",
        taxonomy: "styles"
      },
      {
        id: 304,
        name: "sticky",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 303,
        name: "dorian",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 580,
    title: "zone brass",
    name: "zone-brass",
    tags: [
      {
        id: 302,
        name: "basic",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 253,
        name: "16th shuffle",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 301,
        name: "thumb",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 579,
    title: "washing shoe",
    name: "washing-shoe",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 299,
        name: "warm",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 578,
    title: "rectangle air",
    name: "rectangle-air",
    tags: [
      {
        id: 297,
        name: "deep",
        taxonomy: "styles"
      },
      {
        id: 296,
        name: "laidback",
        taxonomy: "styles"
      },
      {
        id: 298,
        name: "smooth",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 577,
    title: "on harpune",
    name: "on-harpune",
    tags: [
      {
        id: 295,
        name: "funky",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 288,
        name: "wah wah",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 576,
    title: "binding wax",
    name: "binding-wax",
    tags: [
      {
        id: 294,
        name: "melodic",
        taxonomy: "styles"
      },
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 247,
        name: "9/min9",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 575,
    title: "pine-y tower",
    name: "pine-y-tower",
    tags: [
      {
        id: 293,
        name: "relaxed",
        taxonomy: "styles"
      },
      {
        id: 292,
        name: "soulful",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 244,
        name: "7#9/min7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 291,
        name: "finger",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  },
  {
    id: 574,
    title: "sugar knox",
    name: "sugar-knox",
    tags: [
      {
        id: 290,
        name: "busy",
        taxonomy: "styles"
      },
      {
        id: 289,
        name: "percussive",
        taxonomy: "styles"
      },
      {
        id: 288,
        name: "wah wah",
        taxonomy: "styles"
      },
      {
        id: 230,
        name: "straight",
        taxonomy: "groove"
      },
      {
        id: 240,
        name: "only b3+b7",
        taxonomy: "scale"
      },
      {
        id: 285,
        name: "modal",
        taxonomy: "progression"
      },
      {
        id: 284,
        name: "slap",
        taxonomy: "technique"
      },
      {
        id: 283,
        name: "75",
        taxonomy: "tempo"
      }
    ]
  }
];
