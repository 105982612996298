import { useLocation } from "react-router-dom";

// Always use this hook to access search instead of using useLocation().search directly
// because initial values are removed here
export function useLocationSearch() {
  const searchString = useLocation().search;

  // remove search params used for inital values and not for keeping state
  const search = new URLSearchParams(searchString);
  if (search.has("playmode")) {
    search.delete("playmode");
  }
  if (search.has("repeat")) {
    search.delete("repeat");
  }

  return search.toString() ? `?${search.toString()}` : "";
}
