import { useEffect, useState } from "react";
import { DefaultTheme } from "styled-components";
import { Mode, theme } from "./theme";
import { RGB } from "./styled.d";

interface RandomThemeApi {
  theme?: DefaultTheme;
  loading: boolean;
}

export type ColormindPalette = [RGB, RGB, RGB, RGB, RGB];

export default function useRandomTheme({ seed, colorScheme }: { seed?: number; colorScheme: Mode }): RandomThemeApi {
  const [palette, setPalette] = useState<undefined | ColormindPalette>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (seed !== undefined) {
      setLoading(true);
      fetch("http://colormind.io/api/", {
        method: "POST",
        body: JSON.stringify({
          model: "ui"
        })
      })
        .then<{ result: ColormindPalette }>((response) => response.json())
        .then((data) => {
          setPalette(data.result);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [seed]);

  return {
    theme: palette ? theme(colorScheme, palette) : undefined,
    loading
  };
}
