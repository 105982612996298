import { useEffect } from "react";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { LoopName } from "./AudioPlayer/types";
import useAudioPlayer from "./AudioPlayer/useAudioPlayer";
import Download from "./Download";
import { Container, Link, textAside } from "./global.sc";
import { useSetMiniplayer } from "./MiniPlayerProvider";
import { useLocationSearch } from "./useLocationSearch";
import useLoops from "./useLoops";

let playerAlreadyTurnedOff = false;

function DownloadExploredLoopRoute({
  match,
  setRandomThemeSeed,
  hasRandomTheme,
  loadingTheme
}: RouteComponentProps<{ loopName: LoopName }> & {
  setRandomThemeSeed: () => void;
  hasRandomTheme: boolean;
  loadingTheme: boolean;
}) {
  const { loopForSlug } = useLoops();
  useSetMiniplayer(true);
  const matchToBack = useRouteMatch("/:loopName");
  const { pause, play, playerState } = useAudioPlayer();
  const isPlaying = playerState ? playerState.playing : false;
  const locationSearch = useLocationSearch();

  const SomeFun = styled.p`
    ${textAside}
  `;

  // Turn off player in case it is playing, so the views stop moving while the user downloads
  useEffect(() => {
    if (isPlaying && !playerAlreadyTurnedOff) {
      pause();
      return () => {
        if (isPlaying) {
          play();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // effect should only run once

  const loop = loopForSlug(match.params.loopName);

  if (!loop) {
    return null;
  }

  return (
    <Container small>
      <Download loop={loop} />
      <br />
      <SomeFun>
        And now be funky and choose{" "}
        {loadingTheme ? (
          "loading... "
        ) : (
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setRandomThemeSeed();
            }}
          >
            {hasRandomTheme ? "another" : "a"} random theme
          </Link>
        )}{" "}
        or
      </SomeFun>
      <br />
      <Link to={`${matchToBack?.url}${locationSearch}`}>Go back</Link>
    </Container>
  );
}

export default DownloadExploredLoopRoute;
