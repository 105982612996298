import { Track, AudioTrack, LoopName, Playmode } from "./types";
import { Howl } from "howler";

export interface PlayerState {
  currentTrack?: Track;
  playing: boolean;
  repeat: boolean;
  speed: Speed;
  playmode: Playmode;
  loadErrors: LoopName[];
  playErrors: LoopName[];
}

type SetStateAction = (prevState: PlayerState) => PlayerState;
type OnChangeHandler = (state: PlayerState, prevState: PlayerState) => void;
export type Speed = 1 | 1.5 | 2;

const speeds: Speed[] = [1, 1.5, 2];

const CROSSFADE_TIME_OUT = 30;

interface Options {
  onChange?: OnChangeHandler;
  repeat: boolean;
  speed: Speed;
  autoplay?: boolean;
  initialLoop?: LoopName;
}

interface CreateAudioTrackInput {
  trackIdx: number;
  audioPostfix: string;
  loopName: LoopName;
  isLast: (trackIdx: number) => boolean;
  variant?: number;
  speed: Speed;
}

const PRELOAD_NUMBER = 1;

export const AUDIOS_PER_LOOP_IN_ALL_MODE = 5; // In "all" mode we have 5 audios per loop
export default class Player {
  private preloaded: Set<number> = new Set();

  private audioTracks: AudioTrack[] = [];
  private state: PlayerState = {
    currentTrack: undefined,
    playing: false,
    repeat: false,
    playmode: "solo",
    speed: 1,
    loadErrors: [],
    playErrors: []
  };
  private isAllMode = false;

  private onChange?: OnChangeHandler;

  private unlinked = false;

  constructor(
    loops: string[],
    playmode: Playmode,
    { initialLoop, repeat, onChange, autoplay, speed }: Options = {
      initialLoop: undefined,
      repeat: false,
      onChange: undefined,
      autoplay: false,
      speed: 1
    }
  ) {
    this.onChange = onChange;
    const initialState: PlayerState = {
      repeat,
      playing: autoplay || false,
      currentTrack: undefined,
      playmode,
      speed,
      loadErrors: [],
      playErrors: []
    };

    if (playmode === "all") {
      this.isAllMode = true;
    }

    const audioTracks = loops.reduce<[Track, Howl][]>((acc, c, idx) => {
      if (!this.isAllMode) {
        const isLast = (idx: number) => idx + 1 === loops.length;
        acc.push(this.createAudioTrack({ loopName: c, trackIdx: idx, isLast, audioPostfix: playmode, speed }));
      } else {
        const trackIdx = idx * AUDIOS_PER_LOOP_IN_ALL_MODE;
        const isLast = (idx: number) => idx + 1 === loops.length * AUDIOS_PER_LOOP_IN_ALL_MODE;
        acc.push(
          this.createAudioTrack({ loopName: c, trackIdx: trackIdx, isLast, audioPostfix: "solo", variant: 1, speed })
        );
        for (let index = 2; index <= AUDIOS_PER_LOOP_IN_ALL_MODE; index++) {
          const innerTrackIdx = trackIdx + index - 1;
          acc.push(
            this.createAudioTrack({
              loopName: c,
              trackIdx: innerTrackIdx,
              isLast,
              audioPostfix: `${index}`,
              variant: index,
              speed
            })
          );
        }
      }
      return acc;
    }, []);
    const currentAudioTrack = audioTracks.find(([track]) => track.slug === initialLoop) || undefined;
    if (currentAudioTrack) {
      initialState.currentTrack = currentAudioTrack[0];
      if (initialState.playing) {
        currentAudioTrack[1].play();
      }
    }
    this.audioTracks = audioTracks;
    this.managePreloads(initialState.currentTrack?.index);

    this.setState((p) => initialState);
  }

  public play(): void {
    const currentAudioTrack = this.currentAudioTrack();
    if (currentAudioTrack) {
      this.setState((p) => {
        if (!p.playing) {
          currentAudioTrack[1].load();
          currentAudioTrack[1].volume(0);
          currentAudioTrack[1].fade(0, 1, CROSSFADE_TIME_OUT);
          currentAudioTrack[1].play();

          return { ...p, playing: true };
        }
        return p;
      });
    }
  }
  public pause(): void {
    const at = this.currentAudioTrack();
    if (at) {
      at[1].fade(1, 0, CROSSFADE_TIME_OUT);

      setTimeout(() => {
        at[1].pause();
      }, CROSSFADE_TIME_OUT);

      this.setState((p) => ({ ...p, playing: false }));
    }
  }

  public togglePlay(): void {
    if (this.state.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  public hasNext(): boolean {
    return !!this.getNextTrack();
  }

  public next(): void {
    const next = this.getNextTrack();
    if (!next) {
      return;
    }

    const currentAudioTrack = this.currentAudioTrack();
    if (!currentAudioTrack) {
      return;
    }

    this.setState((p) => {
      const nextAudioTrack = this.audioTracks[next.index];

      if (p.playing) {
        currentAudioTrack[1].fade(1, 0, CROSSFADE_TIME_OUT);
        nextAudioTrack[1].load().seek(0);

        setTimeout(() => {
          currentAudioTrack[1].pause();
          currentAudioTrack[1].seek(0);
          nextAudioTrack[1].play();
        }, CROSSFADE_TIME_OUT);
      } else {
        currentAudioTrack[1].seek(0);
      }

      return { ...p, currentTrack: nextAudioTrack[0] };
    });
  }

  public hasPrevious(): boolean {
    return !!this.getPreviousTrack();
  }
  public previous(): void {
    const previous = this.getPreviousTrack();
    if (!previous) {
      return;
    }

    const currentAudioTrack = this.currentAudioTrack();
    if (!currentAudioTrack) {
      return;
    }

    this.setState((p) => {
      const prevAudioTrack = this.audioTracks[previous.index];

      if (p.playing) {
        currentAudioTrack[1].fade(1, 0, CROSSFADE_TIME_OUT);
        prevAudioTrack[1].load().seek(0);

        setTimeout(() => {
          currentAudioTrack[1].pause();
          currentAudioTrack[1].seek(0);
          prevAudioTrack[1].play();
        }, CROSSFADE_TIME_OUT);
      } else {
        currentAudioTrack[1].seek(0);
      }

      return { ...p, currentTrack: prevAudioTrack[0] };
    });
  }
  public toggleRepeat(): void {
    const currentAudioTrack = this.currentAudioTrack();
    if (currentAudioTrack) {
      this.setState((p) => {
        return { ...p, repeat: !p.repeat };
      });
    }
  }

  public toggleSpeed(): void {
    const currentAudioTrack = this.currentAudioTrack();
    if (currentAudioTrack) {
      this.setState(({ speed, ...rest }) => {
        const oldSpeedIndex = speeds.findIndex((c) => c === speed);

        // set to beginning if old index is invalid or at the end
        const newSpeed =
          oldSpeedIndex === -1 || oldSpeedIndex === speeds.length - 1 ? speeds[0] : speeds[oldSpeedIndex + 1];

        // apply new speed to all audios
        this.audioTracks.forEach((c) => {
          c[1].rate(newSpeed);
        });

        return { ...rest, speed: newSpeed };
      });
    }
  }

  public moveCurrentTrackTo(loopName: LoopName): boolean {
    const trackToMoveTo = this.trackForLoopName(loopName);
    if (!trackToMoveTo) {
      return false;
    }
    const currentAudioTrack = this.currentAudioTrack();
    const audioTrackToMoveTo = this.audioTracks[trackToMoveTo.index];

    this.setState((p) => {
      audioTrackToMoveTo[1].seek(0);
      if (p.playing && currentAudioTrack) {
        currentAudioTrack[1].pause();
        audioTrackToMoveTo[1].load().play();
      }
      currentAudioTrack && currentAudioTrack[1].seek(0);

      return { ...p, currentTrack: trackToMoveTo };
    });

    return true;
  }

  public moveCurrentTrackToFirstOfList(): LoopName {
    const audioTrackToMoveTo = this.audioTracks[0];
    this.moveCurrentTrackTo(audioTrackToMoveTo[0].slug);
    return audioTrackToMoveTo[0].slug;
  }

  public getState(): PlayerState {
    return this.state;
  }

  public getCurrentCompleteness(): number | undefined {
    const currentAudioTrack = this.currentAudioTrack();
    if (!currentAudioTrack) {
      return undefined;
    }
    if (currentAudioTrack[1].state() !== "loaded") {
      return undefined;
    }

    const duration = currentAudioTrack[1].duration();
    const seek = currentAudioTrack[1].seek() as number;
    if (!this.isAllMode) {
      return Math.round((seek / duration) * 1000) / 1000;
    } else {
      const indexOfLoop = currentAudioTrack[0].index % AUDIOS_PER_LOOP_IN_ALL_MODE;
      const completeness =
        Math.round(((indexOfLoop * duration + seek || 0) / (duration * AUDIOS_PER_LOOP_IN_ALL_MODE)) * 1000) / 1000;

      return completeness;
    }
  }

  /**
   * Unlink audioplayer and onChange-handler is no more executed,
   * stops sending state updates to the outside
   */
  public unlink() {
    this.unlinked = true;
  }

  private currentAudioTrack(): undefined | AudioTrack {
    if (this.state.currentTrack) {
      return this.audioTracks[this.state.currentTrack.index];
    }
  }

  private getNextTrack(): Track | null {
    const currentAudioTrack = this.currentAudioTrack();
    if (!currentAudioTrack) {
      return null;
    }

    return this.nextTrackForLoopName(currentAudioTrack[0].slug);
  }

  private getPreviousTrack(): Track | null {
    const currentAudioTrack = this.currentAudioTrack();
    if (!currentAudioTrack) {
      return null;
    }

    return this.prevTrackForLoopName(currentAudioTrack[0].slug);
  }

  // To skip the update,
  // return the same reference to previous state
  private setState(action: SetStateAction): void {
    const prevState = { ...this.state }; // copy
    const nextState = action(prevState);
    if (nextState !== prevState) {
      this.state = nextState;
      if (this.onChange && !this.unlinked) {
        this.onChange(this.state, prevState);
      }
    }
  }
  private managePreloads(trackIdx?: number) {
    if (undefined === trackIdx) {
      return;
    }
    if (PRELOAD_NUMBER) {
      for (let index = trackIdx; index <= trackIdx + PRELOAD_NUMBER; index++) {
        if (this.audioTracks[index] && !this.preloaded.has(index)) {
          this.audioTracks[index][1].load();
          this.preloaded.add(index);
        }
      }
    }
  }

  private createAudioTrack({
    audioPostfix,
    loopName,
    isLast,
    trackIdx,
    variant,
    speed
  }: CreateAudioTrackInput): AudioTrack {
    const track: Track = {
      index: trackIdx,
      slug: loopName,
      variant
    };

    // Sets the current audioTrack to state BEFORE the audio is played
    const playAudioAndSetState = (audioTrack: AudioTrack, options = { skipLoad: false }) => {
      if (!options.skipLoad) {
        audioTrack[1].load();
      }
      this.setState((p) => ({ ...p, currentTrack: audioTrack[0] }));
      audioTrack[1].play();
    };

    let cancelId: number | undefined = undefined;

    // fade out the last bit of a playing song, to avoid audio-clipping
    function fadeOutBeforeEnd(howl: Howl) {
      let fadeOutStarted = false;
      return () => {
        const position = howl.seek() as number;
        const duration = howl.duration();
        const timeLeft = duration - position;

        if (position < 0.5) {
          fadeOutStarted = false;
        }
        if (!fadeOutStarted && timeLeft < CROSSFADE_TIME_OUT / 1000) {
          fadeOutStarted = true;
          sound.fade(1, 0, timeLeft * 1000);
        }
      };
    }

    const sound = new Howl({
      src: [`${process.env.REACT_APP_LOOP_CONTENT_URL}/preview/${loopName}-${audioPostfix}.mp3`],
      autoplay: false,
      volume: 1,
      onloaderror: () => {
        this.setState((p) => {
          return { ...p, loadErrors: [...p.loadErrors, loopName] };
        });
      },
      onplayerror: () => {
        this.setState((p) => {
          return { ...p, playErrors: [...p.playErrors, loopName] };
        });
      },
      rate: speed,
      preload: false, // we manage our own preload strategy
      onend: () => {
        if (cancelId) {
          clearInterval(cancelId);
          cancelId = undefined;
        }
        sound.volume(1);
        if (!this.state.repeat) {
          if (!isLast(trackIdx)) {
            playAudioAndSetState(this.audioTracks[trackIdx + 1]);
          } else {
            // this.setState((p) => ({ ...p, playing: false })); // stop on end
            playAudioAndSetState(this.audioTracks[0]); // start from beginning
          }
        } else {
          // when repeat:
          if (!this.isAllMode) {
            this.audioTracks[trackIdx][1].play();
          } else {
            switch (variant) {
              case AUDIOS_PER_LOOP_IN_ALL_MODE: {
                // loop back to first track of loop
                playAudioAndSetState(this.audioTracks[trackIdx - AUDIOS_PER_LOOP_IN_ALL_MODE + 1]);
                break;
              }
              default: {
                // next track
                playAudioAndSetState(this.audioTracks[trackIdx + 1]);
                break;
              }
            }
          }
        }
      },

      onpause: () => {
        if (cancelId) {
          clearInterval(cancelId);
          cancelId = undefined;
        }
        sound.volume(1);
      },
      onplay: () => {
        if (!cancelId) {
          cancelId = window.setInterval(fadeOutBeforeEnd(sound), 25);
        }
        sound.volume(1);

        this.managePreloads(trackIdx);

        this.setState((p) => {
          if (
            p.currentTrack &&
            p.currentTrack.slug === track.slug &&
            p.currentTrack.index === track.index &&
            p.currentTrack.variant === track.variant
          ) {
            return p; //no update
          }

          return { ...p, currentTrack: track };
        });
      }
    });
    return [track, sound];
  }

  private trackForLoopName(loopName: LoopName): Track | null {
    for (let c of this.audioTracks.map((c) => c[0])) {
      if (c.slug === loopName) {
        return c;
      }
    }

    return null;
  }

  private nextTrackForLoopName(loopName: LoopName): Track | null {
    let loopNameFound = false;

    for (let c of [...this.audioTracks.map((c) => c[0]), ...this.audioTracks.map((c) => c[0])]) {
      if (c.slug === loopName) {
        loopNameFound = true;
      } else {
        if (loopNameFound && c.slug !== loopName) {
          return c;
        }
      }
    }

    return null;
  }

  private prevTrackForLoopName(loopName: LoopName): Track | null {
    let loopNameFound = false;

    for (let c of [...this.audioTracks.map((c) => c[0]), ...this.audioTracks.map((c) => c[0])].reverse()) {
      if (c.slug === loopName) {
        loopNameFound = true;
      } else {
        if (loopNameFound && c.slug !== loopName) {
          return this.audioTracks.map((c) => c[0]).find((c2) => c2.slug === c.slug) || null; //find first of previous
        }
      }
    }

    return null;
  }
  private isFirstForLoopName(loopName: LoopName): boolean {
    if (!this.audioTracks.length) {
      return false;
    }
    const first = this.audioTracks[0];

    return first[0].slug === loopName;
  }
  private isLastForLoopName(loopName: LoopName): boolean {
    if (!this.audioTracks.length) {
      return false;
    }
    const last = this.audioTracks[this.audioTracks.length - 1];

    return last[0].slug === loopName;
  }
}
