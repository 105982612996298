import styled from "styled-components";
import { Button as BaseButton } from "../global.sc";
import useTags from "../useTags";

const Root = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.main};
  max-width: 320px;
  margin: 0 auto;
  box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.3);
  padding: 16px;
`;

const Headline = styled.h2`
  color: ${({ theme }) => theme.palette.text.aside};

  position: relative;
  margin-top: 0px;
`;

const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.palette.text.aside};

  &:hover {
    color: ${({ theme }) => theme.palette.text.asideContrast};
  }
`;

function Console() {
  const { removeAllTags } = useTags();
  return (
    <Root>
      <Headline>Oups, I'm outa loops</Headline>
      <Button onClick={removeAllTags}>reset filter</Button>
    </Root>
  );
}

export default Console;
