import { Tag } from "./loops";

export const tags: Tag[] = [
  {
    id: 230,
    name: "straight",
    taxonomy: "groove"
  },
  {
    id: 253,
    name: "16th shuffle",
    taxonomy: "groove"
  },
  {
    id: 285,
    name: "modal",
    taxonomy: "progression"
  },
  {
    id: 308,
    name: "2 chords",
    taxonomy: "progression"
  },
  {
    id: 316,
    name: "3 chords",
    taxonomy: "progression"
  },
  {
    id: 318,
    name: "4 chords",
    taxonomy: "progression"
  },
  {
    id: 240,
    name: "only b3+b7",
    taxonomy: "scale"
  },
  {
    id: 244,
    name: "7#9/min7",
    taxonomy: "scale"
  },
  {
    id: 247,
    name: "9/min9",
    taxonomy: "scale"
  },
  {
    id: 303,
    name: "dorian",
    taxonomy: "scale"
  },
  {
    id: 263,
    name: "only 5+b7",
    taxonomy: "scale"
  },
  {
    id: 266,
    name: "7",
    taxonomy: "scale"
  },
  {
    id: 267,
    name: "min7",
    taxonomy: "scale"
  },
  {
    id: 272,
    name: "min9",
    taxonomy: "scale"
  },
  {
    id: 317,
    name: "aeolian",
    taxonomy: "scale"
  },
  {
    id: 319,
    name: "harmonic-minor",
    taxonomy: "scale"
  },
  {
    id: 288,
    name: "wah wah",
    taxonomy: "styles"
  },
  {
    id: 289,
    name: "percussive",
    taxonomy: "styles"
  },
  {
    id: 290,
    name: "busy",
    taxonomy: "styles"
  },
  {
    id: 292,
    name: "soulful",
    taxonomy: "styles"
  },
  {
    id: 293,
    name: "relaxed",
    taxonomy: "styles"
  },
  {
    id: 294,
    name: "melodic",
    taxonomy: "styles"
  },
  {
    id: 295,
    name: "funky",
    taxonomy: "styles"
  },
  {
    id: 296,
    name: "laidback",
    taxonomy: "styles"
  },
  {
    id: 297,
    name: "deep",
    taxonomy: "styles"
  },
  {
    id: 298,
    name: "smooth",
    taxonomy: "styles"
  },
  {
    id: 299,
    name: "warm",
    taxonomy: "styles"
  },
  {
    id: 302,
    name: "basic",
    taxonomy: "styles"
  },
  {
    id: 304,
    name: "sticky",
    taxonomy: "styles"
  },
  {
    id: 305,
    name: "minimal",
    taxonomy: "styles"
  },
  {
    id: 306,
    name: "syncoped",
    taxonomy: "styles"
  },
  {
    id: 309,
    name: "dirty",
    taxonomy: "styles"
  },
  {
    id: 310,
    name: "oldschool",
    taxonomy: "styles"
  },
  {
    id: 311,
    name: "chordal",
    taxonomy: "styles"
  },
  {
    id: 312,
    name: "behind the pulse",
    taxonomy: "styles"
  },
  {
    id: 313,
    name: "hip hop",
    taxonomy: "styles"
  },
  {
    id: 314,
    name: "dreamy",
    taxonomy: "styles"
  },
  {
    id: 320,
    name: "latin",
    taxonomy: "styles"
  },
  {
    id: 321,
    name: "rock",
    taxonomy: "styles"
  },
  {
    id: 323,
    name: "funky house",
    taxonomy: "styles"
  },
  {
    id: 284,
    name: "slap",
    taxonomy: "technique"
  },
  {
    id: 291,
    name: "finger",
    taxonomy: "technique"
  },
  {
    id: 301,
    name: "thumb",
    taxonomy: "technique"
  },
  {
    id: 283,
    name: "75",
    taxonomy: "tempo"
  },
  {
    id: 307,
    name: "90",
    taxonomy: "tempo"
  },
  {
    id: 315,
    name: "110",
    taxonomy: "tempo"
  },
  {
    id: 322,
    name: "125",
    taxonomy: "tempo"
  },
  {
    id: 1000,
    name: "superrare.co",
    taxonomy: "available"
  }
];
