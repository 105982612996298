import styled from "styled-components";
import DownloadLink from "./DownloadLink";

import { Loop } from "./loopData/loops";

interface Props {
  loop: Loop;
}

const Root = styled.div`
  max-width: 320px;
  margin: 0 auto;
`;
const Headline = styled.h2`
  text-transform: uppercase;
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
`;

const Table = styled.table`
  margin: 0 auto;
  text-align: left;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  td {
    vertical-align: middle;
  }
  thead {
    tr {
      th {
        padding: 0;
        border: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
        color: inherit;
        vertical-align: bottom;
        font-size: 12px;
        padding: 8px;
      }
    }
  }
  tbody {
    tr:first-of-type {
      td {
        border-top: 0;
      }
    }
    tr:last-of-type {
      td {
        border-bottom: 0;
      }
    }
    tr {
      td {
        vertical-align: middle;
        border: 1px solid ${({ theme }) => theme.table.borderColor};
        padding: 8px;
        line-height: 1.428571429;
        &:last-of-type {
          border-right: 0;
        }
        &:first-of-type {
          border-left: 0;
        }
      }
    }
  }
`;

function Download({ loop }: Props) {
  return (
    <Root>
      <Headline>{loop.title}</Headline>
      Download your favorite format.
      <br />
      <br />
      <Table>
        <thead>
          <tr>
            <th>Format</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>rex2</td>
            <td>
              <DownloadLink format="rx2" loop={loop} />
            </td>
          </tr>
          <tr>
            <td>wav</td>
            <td>
              <DownloadLink format="wav" loop={loop} />
            </td>
          </tr>
          <tr>
            <td>aiff</td>
            <td>
              <DownloadLink format="aif" loop={loop} />
            </td>
          </tr>
        </tbody>
      </Table>
    </Root>
  );
}

export default Download;
