import { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Icon from "./Icon";
import useCurrentCompleteness from "./AudioPlayer/useCurrentCompleteness";
import { useLocationSearch } from "./useLocationSearch";

interface Props {
  children: string;
}

const Root = styled.div`
  width: 100%;
  text-align: center;
`;

const Root2 = styled.div`
  cursor: pointer;
  transition: all ease-out 0.5s;
`;

const TitleAreaLink = styled(Link)`
  display: inline-block;
  text-align: right;
  margin-bottom: 6px;
  position: relative;
  text-decoration: none;
`;

const DownloadIcon = styled(Icon)`
  position: absolute;
  right: -46px;
  bottom: -1px;
  font-size: 30px;
`;

const TitleDefault = styled.h2`
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0;
  color: ${({ theme }) => theme.exploredLoop.title.colorIncomplete};
`;
const TitleProgress = styled.h2`
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.1;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0;
  color: ${({ theme }) => theme.exploredLoop.title.color};
  white-space: nowrap;
  position: absolute;
  width: 0.1%;
  overflow: hidden;
`;

function ExploredLoop({ children }: Props) {
  const match = useRouteMatch("/:loopName");
  const [hoverd, setHovered] = useState(false);
  const completePercent = useCurrentCompleteness();
  const locationSearch = useLocationSearch();
  return (
    <Root>
      <Root2>
        <TitleAreaLink
          to={`${match?.url}/download${locationSearch}`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <DownloadIcon symbol={hoverd ? "cloud-negative" : "cloud"} size={30} />
          <TitleProgress style={{ width: completePercent ? `${completePercent * 100}%` : "100%" }}>
            {children}
          </TitleProgress>
          <TitleDefault>{children}</TitleDefault>
        </TitleAreaLink>
      </Root2>
    </Root>
  );
}

export default ExploredLoop;
