import { Palette, RGB } from "./styled.d";
import { DefaultTheme } from "styled-components";
import { ColormindPalette } from "./useRandomTheme";

export type Mode = "dark" | "light";

const paletteDark: Palette = {
  primary: {
    main: "#ff0d5e", // lighter
    light: "#ff73a2"
  },
  text: {
    main: "#fafafa",
    mainRGB: [250, 250, 250],
    contrast: "#1b1923",
    aside: "#fafafa",
    asideContrast: "#1b1923"
  },
  background: {
    default: "#1b1923",
    defaultRGB: [27, 25, 35],
    aside: "#46404D"
  },
  gray: {
    light: rgba([238, 238, 238], 0.2)
  }
};

const paletteLight: Palette = {
  primary: {
    main: "#990033",
    light: "#de004a"
  },
  text: {
    main: "#1b1923",
    mainRGB: [26, 41, 49],
    contrast: "#fafafa",
    aside: "#fafafa",
    asideContrast: "#1b1923"
  },
  background: {
    default: "#fafafa",
    defaultRGB: [250, 250, 248],
    aside: "#46404D"
  },
  gray: {
    light: rgba([220, 220, 220])
  }
};

function theme(mode: Mode = "dark", colormindPalette?: ColormindPalette): DefaultTheme {
  const exploredLoopBackgroundOpacity = mode === "light" ? 0.95 : 0.85;
  const audioPlayerControlsPulseRgb = mode === "light" ? 50 : 255;

  const palette = colormindPalette
    ? colorMindPaletteToPalette(colormindPalette, mode)
    : mode === "light"
    ? paletteLight
    : paletteDark;

  return {
    palette,
    container: {
      verticalPadding: "20px"
    },
    header: {
      height: 60
    },
    exploredLoop: {
      height: "180px",
      title: {
        color: palette.text.main,
        colorIncomplete: rgba(palette.text.mainRGB, 0.5)
      },
      background: rgba(palette.background.defaultRGB, exploredLoopBackgroundOpacity)
    },
    audioPlayer: {
      controls: {
        height: {
          xs: 130,
          xsMiniPlayer: 70,
          sm: 74
        },
        pulseShadowDepth: audioPlayerControlsPulseRgb
      }
    },
    tags: {
      styles: { color: mode === "light" ? "red" : "#cc3232" }, // dark mode has 60% saturation
      tempo: { color: mode === "light" ? "#990033" : "#ff0d5e" },
      progression: { color: mode === "light" ? "green" : "#196619" },
      groove: { color: mode === "light" ? "blue" : "#3232cc" },
      scale: { color: mode === "light" ? "orange" : "#cc9632" },
      technique: { color: mode === "light" ? "brown" : "#a52929" },
      available: { color: mode === "light" ? "silver" : "#e59a9a" }
    },
    table: {
      borderColor: palette.gray.light
    },
    breakpoint: `767px` // min
  };
}

export { theme };

// supports only 6 char hex!
function rgba([r, g, b]: RGB, alpha?: number): string {
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}

function colorMindPaletteToPalette(colormind: ColormindPalette, mode: Mode): Palette {
  return {
    primary: mode === "light" ? paletteLight.primary : paletteDark.primary,
    text: {
      main: rgba(mode === "light" ? colormind[4] : colormind[0]),
      mainRGB: mode === "light" ? colormind[4] : colormind[0],
      contrast: rgba(mode === "light" ? colormind[0] : colormind[0]),
      aside: rgba(mode === "light" ? colormind[1] : colormind[1]),
      asideContrast: rgba(mode === "light" ? colormind[1] : colormind[2])
    },
    background: {
      default: rgba(mode === "light" ? colormind[0] : colormind[4]),
      defaultRGB: mode === "light" ? colormind[0] : colormind[4],
      aside: rgba(mode === "light" ? colormind[2] : colormind[3])
    },
    gray: {
      light: mode === "dark" ? rgba([238, 238, 238], 0.2) : rgba([220, 220, 220])
    }
  };
}

// const classicDark: Palette = {
//   primary: {
//     main: "#990033"
//   },
//   text: {
//     main: "#dddddd",
//     mainRGB: [221, 221, 221], // rgb(250,250,250)
//     contrast: "#dddddd",
//     aside: "#dddddd"
//   },
//   background: {
//     default: "#000000",
//     defaultRGB: [0, 0, 0], // rgb(27,25,35)
//     aside: "#333" // rgb(116,137,168)
//   },
//   gray: {
//     light: rgba([238, 238, 238], 0.2)
//   }
// };
