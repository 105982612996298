import { useCallback, useEffect, useRef, useState } from "react";

type ToggleFn = () => void;

export default function useColorScheme(): ["light" | "dark", ToggleFn] {
  const lightModeMediaQueryRef = useRef(window.matchMedia("(prefers-color-scheme: light)"));
  const [mode, setMode] = useState<"light" | "dark">(lightModeMediaQueryRef.current.matches ? "light" : "dark");

  const toggleMode = useCallback(() => setMode((s) => (s === "light" ? "dark" : "light")), []);

  useEffect(() => {
    function handleChange(e: MediaQueryListEvent) {
      setMode(e.matches ? "dark" : "light");
    }
    const lightModeMediaQuery = lightModeMediaQueryRef.current;
    lightModeMediaQuery.addListener(handleChange);
    return () => {
      lightModeMediaQuery.removeListener(handleChange);
    };
  }, []);
  return [mode, toggleMode];
}
