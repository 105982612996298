import AudioplayerProvider from "./AudioPlayer/AudioPlayerProvider";
import { Controls } from "./AudioPlayer/Controls";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoopNavigator from "./LoopNavigator/LoopNavigator";
import ExploredLoopRoute from "./ExploredLoopRoute";
import DownloadExploredLoopRoute from "./DownloadExploredLoopRoute";
import { TransitionGroup, Transition } from "react-transition-group";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { Main, Site } from "./App.sc";
import Header from "./Header";
import GlobalStyle, { Page } from "./global.sc";
import LoopFilter from "./LoopFilter";
import { useCallback, useState } from "react";
import useRandomTheme, { ColormindPalette } from "./useRandomTheme";
import useColorScheme from "./useColorScheme";
import MiniPlayerProvider from "./MiniPlayerProvider";
import AboutRoute from "./AboutRoute";

const favortitePalette: ColormindPalette = [
  [232, 209, 189],
  [184, 183, 152],
  [144, 126, 108],
  [102, 88, 90],
  [48, 52, 50]
];

function App() {
  const [colorScheme, toggleColorScheme] = useColorScheme();
  const [randomThemeSeed, setThemeSeed] = useState<number | undefined>(undefined);
  const { theme: randomTheme, loading: loadingTheme } = useRandomTheme({ seed: randomThemeSeed, colorScheme });
  const setRandomThemeSeed = useCallback(() => {
    setThemeSeed(Math.random());
  }, []);

  // Third "OR" will never be applyed at the moment, we only use ColormindPalette
  return (
    <ThemeProvider theme={randomTheme || theme(colorScheme, favortitePalette) || theme(colorScheme)}>
      <GlobalStyle />
      <Router>
        <MiniPlayerProvider>
          <Site>
            <Header colorScheme={colorScheme} toggleColorScheme={toggleColorScheme} />
            <Main>
              <Route strict={false} path="/:loopName?">
                {({ location }) => (
                  <AudioplayerProvider>
                    {({ loopCount }) => (
                      <>
                        <LoopNavigator>
                          <LoopNavigator.LoopContent>
                            <TransitionGroup>
                              <Transition key={location.key} timeout={300}>
                                {(transitionStatus) => (
                                  <Switch location={location}>
                                    <Route exact={true} path="/info" />
                                    <Route
                                      exact={true}
                                      path="/:loopName"
                                      render={(routerProps) => (
                                        <Page centered noOverflow>
                                          {loopCount ? (
                                            <ExploredLoopRoute {...routerProps} transitionStatus={transitionStatus} />
                                          ) : (
                                            <LoopNavigator.Console />
                                          )}
                                        </Page>
                                      )}
                                    />
                                  </Switch>
                                )}
                              </Transition>
                            </TransitionGroup>
                            <Switch>
                              <Route exact={true} path="/info">
                                <Page border background>
                                  <AboutRoute />
                                </Page>
                              </Route>
                              <Route
                                exact={true}
                                path="/:loopName/download"
                                render={(routerProps) => (
                                  <Page border background>
                                    <DownloadExploredLoopRoute
                                      {...routerProps}
                                      hasRandomTheme={!!randomTheme}
                                      setRandomThemeSeed={setRandomThemeSeed}
                                      loadingTheme={loadingTheme}
                                    />
                                  </Page>
                                )}
                              />
                            </Switch>
                          </LoopNavigator.LoopContent>
                        </LoopNavigator>
                        <Controls />
                        <LoopFilter />
                      </>
                    )}
                  </AudioplayerProvider>
                )}
              </Route>
            </Main>
          </Site>
        </MiniPlayerProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
