import iconData, { IconData } from "./iconData";
import styled from "styled-components";

interface Props {
  className?: string;
  size?: number;
  variant?: "default" | "primary";
  children?: React.ReactNode;
  symbol?: keyof IconData;
}

const Svg = styled.svg<{ variant: string }>`
  fill: ${({ theme, variant }) => (variant === "primary" ? theme.palette.primary.main : theme.palette.text.main)};
`;

function Icon(props: Props) {
  const { className, size = 32, variant = "default" } = props;
  return (
    <Svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      variant={variant}
    >
      {props.children ? props.children : props.symbol ? <path d={iconData[props.symbol]} /> : null}
    </Svg>
  );
}

export default Icon;
