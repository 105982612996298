import { createContext, ReactNode, useState, useCallback, useEffect, useRef, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useLocationSearch } from "../useLocationSearch";
import useLoops from "../useLoops";
import { usePrevious } from "../usePrevious";
import Player, { PlayerState } from "./Player";
import { LoopName, Playmode } from "./types";

export const AudioplayerProviderContext = createContext<AudioPlayerApi | undefined>(undefined);

interface AudioPlayerApi {
  play(): void;
  pause(): void;
  next(): void;
  previous(): void;
  playmode: Playmode;
  setPlaymode: React.Dispatch<React.SetStateAction<Playmode>>;
  toggleRepeat: () => void;
  toggleSpeed: () => void;
  playerState?: PlayerState;
  getCompleteness: () => number | undefined;
  playerReady: boolean; // Not used
  togglePlay: () => void;
  direction: Direction;
  userDidNotPressPlayYet: boolean;
  linkToCurrentLoop?: string;
}

interface Props {
  children: (api: { loopCount: number }) => ReactNode;
  defaultRepeat?: boolean;
  defaultPlaymode?: Playmode;
}

export type Direction = "backward" | "forward";
export type Speed = 1 | 1.5 | 2;

const blockedLoopNames = ["info"];
const validPlaymodes: Playmode[] = ["all", "full", "solo"];

const MAX_AUDIOS_UNTIL_AUTO_STOP = 150;

const isLoopNameValid = (loopName: LoopName) => !blockedLoopNames.includes(loopName);

export default function AudioplayerProvider({ children, defaultPlaymode = "solo", defaultRepeat = false }: Props) {
  const { loops } = useLoops();
  const loopNames = useMemo(() => loops.map((c) => c.name), [loops]);
  const player = useRef<Player>();
  const [playerState, setPlayerState] = useState<PlayerState | undefined>();
  const history = useHistory();
  const locationSearch = useLocationSearch();
  const [playerReady, setPlayerReady] = useState(false);
  let { loopName: routerMatchedLoopName } = useParams<{ loopName?: LoopName }>();
  const [direction, setDirection] = useState<Direction>("forward");
  const [userDidNotPressPlayYet, setUserDidNotPressPlayYet] = useState(true);
  const currentLoopNameInPlayer = playerState?.currentTrack?.slug;
  const isPlaying = playerState?.playing ?? false;
  const previousLoopNameInPlayer = usePrevious(currentLoopNameInPlayer);
  const initialValues = useExtractInitialValues();
  const [playmode, setPlaymode] = useState<Playmode>(() => initialValues.current?.playmode || defaultPlaymode);
  const [successivePlaycount, setSuccessivePlaycount] = useState(0);

  const linkToCurrentLoop = currentLoopNameInPlayer ? `/${currentLoopNameInPlayer}${locationSearch}` : undefined;

  const play = useCallback(() => {
    player.current?.play();
    setSuccessivePlaycount(0); // reset playcount on press play
  }, []);
  const pause = useCallback(() => {
    player.current?.pause();
  }, []);
  const toggleRepeat = useCallback(() => {
    player.current?.toggleRepeat();
  }, []);

  const toggleSpeed = useCallback(() => {
    player.current?.toggleSpeed();
  }, []);

  const togglePlay = useCallback(() => {
    setSuccessivePlaycount(0); // reset playcount on press play
    player.current?.togglePlay();
  }, []);

  const next = useCallback(() => {
    setDirection("forward");
    player.current?.next();
  }, []);

  const previous = useCallback(() => {
    setDirection("backward");
    // reset after animation
    setTimeout(() => {
      setDirection("forward");
    }, 1000);
    player.current?.previous();
  }, []);

  const getCompleteness = useCallback(() => {
    return player.current?.getCurrentCompleteness();
  }, []);

  useEffect(() => {
    if (isPlaying) {
      setUserDidNotPressPlayYet(false);
    }
  }, [isPlaying]);

  // Set audioplayer when loop list changes
  // Transist between current and next audioplayer
  useEffect(() => {
    const previous = player.current;
    const nextRepeat = previous?.getState().repeat ?? (initialValues.current?.repeat || defaultRepeat);
    const nextAutoplay = previous?.getState().playing ?? false;
    const playmodeChanged = previous?.getState().playmode !== playmode;
    player.current = new Player(loopNames, playmode, {
      onChange: setPlayerState,
      repeat: nextRepeat,
      autoplay: nextAutoplay,
      // there are 2 cases where this effect can run:
      // - when list of loopNames changes
      // - or when playmode changes
      // in case the playmode changes, we want to start with the same initial loop again
      // in case the list of loopNames changes, we want to start the player from the beginning
      initialLoop: playmodeChanged ? previous?.getState().currentTrack?.slug : loopNames[0],
      speed: previous?.getState().speed || 1
    });

    setPlayerReady(true);

    if (previous) {
      previous.unlink();
      if (previous.getState().playing) {
        previous.pause();
      }
    }
  }, [loopNames, playmode, defaultRepeat, initialValues]);

  // Sync router and audioplayer,
  // Effect runs when either loopname of player or loopname of router changes
  useEffect(() => {
    // Cornercase, we landed on "/" (no routerMatchedLoopName) and player is empty
    if (currentLoopNameInPlayer === undefined && routerMatchedLoopName === undefined) {
      const loopName = player.current?.moveCurrentTrackToFirstOfList();
      if (loopName) {
        history.push(`/${loopName}`);
      }
      // Regular case: loopname in player and loopname in router are not in sync
    } else if (currentLoopNameInPlayer !== routerMatchedLoopName) {
      const changeTriggeredByRouter = previousLoopNameInPlayer === currentLoopNameInPlayer; //when previous loopname of player nd cuurent loopname of player are equal, this means change was triggered by router
      if (changeTriggeredByRouter) {
        if (routerMatchedLoopName && isLoopNameValid(routerMatchedLoopName)) {
          const success = player.current?.moveCurrentTrackTo(routerMatchedLoopName);

          if (!success) {
            // We have a 404, reset selection
            history.push(`/`);
          }
        }
      } else {
        if (routerMatchedLoopName && !blockedLoopNames.includes(routerMatchedLoopName)) {
          history.push(`/${currentLoopNameInPlayer}${locationSearch}`);
        }
      }
    }
  }, [routerMatchedLoopName, currentLoopNameInPlayer, history, locationSearch, previousLoopNameInPlayer]);

  // Keep track of successive plays
  useEffect(() => {
    setSuccessivePlaycount((s) => s + 1);
  }, [currentLoopNameInPlayer]);

  // Autostop after a while to reduce uninteded network-traffic
  useEffect(() => {
    if (successivePlaycount > MAX_AUDIOS_UNTIL_AUTO_STOP) {
      player.current?.pause();
      setUserDidNotPressPlayYet(true); // activates blinking-play-button
    }
  }, [successivePlaycount]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const key = e.keyCode;
      const SPACE_KEY = 32;
      if (key === SPACE_KEY) {
        togglePlay();
      }
    },
    [togglePlay]
  );

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const api = {
    playmode,
    setPlaymode,
    toggleRepeat,
    play,
    pause,
    next,
    previous,
    playerState,
    getCompleteness,
    playerReady,
    togglePlay,
    direction,
    userDidNotPressPlayYet,
    linkToCurrentLoop,
    toggleSpeed
  };
  return (
    <AudioplayerProviderContext.Provider value={api}>
      {children({ loopCount: loops.length })}
    </AudioplayerProviderContext.Provider>
  );
}

function useExtractInitialValues(): React.RefObject<{
  playmode?: Playmode;
  repeat?: true;
}> {
  const { search } = useLocation();
  const query = useRef(new URLSearchParams(search));
  const ret = useRef<{ playmode?: Playmode; repeat?: true }>({
    playmode: undefined,
    repeat: undefined
  });

  if (query.current) {
    if (query.current.has("playmode") && validPlaymodes.includes(query.current.get("playmode") as Playmode)) {
      ret.current.playmode = query.current.get("playmode") as Playmode;
    }

    if (query.current.has("repeat")) {
      ret.current.repeat = true;
    }
  }

  return ret;
}
