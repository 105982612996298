import styled, { css } from "styled-components";
import LoopTagList from "./LoopTagList";
import useLoops from "./useLoops";
import useTags from "./useTags";

const Root = styled.div<{ open: boolean }>`
  transition: all ease-out 0.3s;
  position: absolute;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.palette.background.aside};
  top: ${({ theme }) => theme.header.height + 20}px;
  right: 0;
  padding: 6px ${({ theme }) => theme.container.verticalPadding};
  box-shadow: -1px 0px 2px -1px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  overflow-x: auto;

  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    position: fixed;

    padding: 12px ${({ theme }) => theme.container.verticalPadding};
    width: 20vw;
    height: 100vh;
    transform: translateX(20vw);
    top: 0;
    ${({ open }) =>
      open &&
      css`
        transform: translateX(0);
      `}
  }
  ${({ open }) =>
    open &&
    css`
      transform: translateX(0);
    `}
`;
const Headline = styled.h6`
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: ${({ theme }) => theme.palette.text.aside};

  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    margin-top: 2em;
    margin-bottom: 2em;
  }
`;

const LoopTagListFirst = styled(LoopTagList)`
  white-space: nowrap;
`;
const LoopTagListSecond = styled(LoopTagList)<{ open: boolean }>`
  ${({ open }) =>
    open &&
    css`
      transition: all ease-out 0.6s 1s;

      opacity: 1;
    `}
`;

const HiddenXs = styled.div`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoint}) {
    display: block;
  }
`;
function LoopFilter() {
  const { removables, removeTag, addables, addTag, selected } = useTags();
  const { loops } = useLoops();
  const open = selected.length > 0;
  return (
    <Root open={open}>
      <Headline>Found {loops.length}</Headline>
      <LoopTagListFirst onClick={removeTag} variant="removeable">
        {removables}
      </LoopTagListFirst>

      <HiddenXs>
        <Headline>All Tags</Headline>
        <LoopTagListSecond onClick={addTag} variant="selectable" open={open}>
          {addables}
        </LoopTagListSecond>
      </HiddenXs>
    </Root>
  );
}

export default LoopFilter;
