import { taxonomies } from "./loopData/taxonomies";
import { useCallback, useMemo } from "react";
import { Tag, Taxonomy } from "./loopData/loops";
import { tags } from "./loopData/tags";
import { useQuery } from "./useQuery";
import { useHistory, useLocation } from "react-router-dom";

interface TagApi {
  tags: Tag[];
  selected: SelectedTag[];
  selectedMap: SelectedTagMap;
  addTag: (tag: SelectedTag) => void;
  removeTag: (tag: SelectedTag) => void;
  removables: Tag[];
  addables: Tag[];
  removeAllTags: () => void;
}

interface SelectedTag {
  id: number;
  taxonomy: Taxonomy;
}

type SelectedTagMap = Partial<Record<Taxonomy, number[]>>;

function formatToSelectedMap(tags: SelectedTag[]): SelectedTagMap {
  return tags.reduce<Partial<Record<Taxonomy, number[]>>>((acc, tag) => {
    return acc[tag.taxonomy]
      ? { ...acc, [tag.taxonomy]: [...(acc[tag.taxonomy] as number[]), tag.id] }
      : { ...acc, [tag.taxonomy]: [tag.id] };
  }, {});
}
function parseQuery(query: URLSearchParams): SelectedTag[] {
  return taxonomies.reduce<SelectedTag[]>((acc, taxonomy) => {
    const value = query
      .get(taxonomy)
      ?.split(",")
      .map((tagId) => Number(tagId))
      .filter((tagId) => tags.find((tag) => tag.id === tagId && tag.taxonomy === taxonomy));

    return value ? [...acc, ...value.map((tagId) => ({ id: tagId, taxonomy }))] : acc;
  }, []);
}

function formatQuery(tags: SelectedTag[]): URLSearchParams {
  const record = formatToSelectedMap(tags);
  return new URLSearchParams(Object.entries(record).map(([key, value]) => [key, value ? value.join(",") : ""]));
}

const matchTag = (testTag: SelectedTag) => (tag: SelectedTag) =>
  tag.taxonomy === testTag.taxonomy && tag.id === testTag.id;

const notMatchTag = (testTag: SelectedTag) => (tag: SelectedTag) =>
  tag.taxonomy !== testTag.taxonomy || tag.id !== testTag.id;

export default function useTags(): TagApi {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const selected = useMemo(() => parseQuery(query), [query]);
  const selectedMap = useMemo(() => formatToSelectedMap(selected), [selected]);

  const addTag = useCallback(
    (addedTag: SelectedTag) => {
      if (!selected.find(matchTag(addedTag))) {
        const newSelected = [...selected, addedTag];

        history.push({ ...location, search: formatQuery(newSelected).toString() });
      }
    },
    [history, location, selected]
  );
  const removeTag = useCallback(
    (removedTag: SelectedTag) => {
      if (selected.find(matchTag(removedTag))) {
        const newSelected = selected.filter(notMatchTag(removedTag));
        history.push({ ...location, search: formatQuery(newSelected).toString() });
      }
    },
    [history, location, selected]
  );
  const removeAllTags = useCallback(() => {
    history.push({ ...location, search: formatQuery([]).toString() });
  }, [history, location]);

  return {
    tags,
    selected,
    selectedMap,
    addTag,
    removeTag,
    removeAllTags,
    removables: tags.filter((c) => selected.some(matchTag(c))),
    addables: tags.filter((c) => selected.every(notMatchTag(c)))
  };
}
