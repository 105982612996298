import { Link as RouterLink } from "react-router-dom";
import styled, { css, createGlobalStyle } from "styled-components";

export const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const FluidContainer = styled.div`
  width: 100%;
  padding-left: ${({ theme }) => theme.container.verticalPadding};
  padding-right: ${({ theme }) => theme.container.verticalPadding};
`;

export const Container = styled(FluidContainer)<{ small: boolean }>`
  max-width: ${({ small }) => (small ? 320 : 900)}px;
  margin: 0 auto;
`;

export const Link = styled(RouterLink)`
  &,
  &:link,
  &:visited,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

export const resetButton = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;
  cursor: pointer;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
`;
export const resetButtonChildren = css`
  button {
    ${resetButton}
  }
`;

export const Button = styled.button`
  ${resetButton}
  text-decoration: underline;
`;

export const Error = styled.p`
  font-style: italic;
`;

export const resetFieldset = css`
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0.01em 0 0 0;
`;
export const resetFieldsetChildren = css`
  legend {
    display: table;
    padding: 0;
  }
  fieldset {
    ${resetFieldset}
  }
  body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
  }
`;

export const textAside = css`
  font-size: 80%;
`;

export const Page = styled.div<{ centered?: boolean; border?: boolean; background?: boolean; noOverflow?: boolean }>`
  background: ${({ theme, background }) => (background ? theme.palette.background.default : "inherit")};

  text-align: left;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  overflow: ${({ noOverflow }) => (noOverflow ? "hidden" : "auto")};

  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      align-content: center;
      align-items: center;
    `}
  ${({ border }) =>
    border &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.gray.light};
    `}
`;

const GlobalStyle = createGlobalStyle`


html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  margin:0;
  padding:0;
}
#root {
  overflow:hidden;

}

body {
  margin: 0;
  font-family: "Volkhov", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
/* @media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
} */


`;
export default GlobalStyle;
