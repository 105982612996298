import styled from "styled-components";
import Icon from "./Icon";
import { SrOnly, FluidContainer, Link, resetButton } from "./global.sc";
import React, { useState } from "react";

const Root = styled.header`
  height: ${({ theme }) => theme.header.height}px;
  padding-top: 10px;
  display: flex;
`;

const Box = styled.div`
  display: flex;
`;
const HeaderLink = styled(Link)`
  &,
  &:link,
  &:visited,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.palette.text.main};
    text-decoration: none;
  }
`;

const LinkHome = styled(HeaderLink)``;

const Title = styled.h1`
  font-size: 1.4em;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.1;
`;

const SubTitle = styled.h6`
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.1;
`;

const LinkInfo = styled(HeaderLink)`
  top: 5px;
  position: relative;
  margin-left: 20px;
`;

const InfoIcon = styled(Icon)<{ $hovered: boolean }>`
  fill: ${({ theme, $hovered }) => ($hovered ? theme.palette.primary.main : theme.palette.text.main)};
`;

const ThemeModeButton = styled.button`
  ${resetButton}
  top: 5px;
  cursor: pointer;
  margin-left: auto;
`;

const MoonIcon = styled(Icon)<{ $hovered: boolean }>`
  fill: ${({ theme, $hovered }) => ($hovered ? theme.palette.primary.main : theme.palette.text.main)};
`;

const SunIcon = styled(Icon)<{ $hovered: boolean }>`
  fill: ${({ theme, $hovered }) => ($hovered ? theme.palette.primary.main : theme.palette.text.main)};
  stroke: ${({ theme, $hovered }) => ($hovered ? theme.palette.primary.main : theme.palette.text.main)};
`;

interface Props {
  onClick?: (e: React.MouseEvent) => void;
  colorScheme: "light" | "dark";
  toggleColorScheme: () => void;
}
function Header({ onClick, colorScheme, toggleColorScheme }: Props) {
  const [infoHovered, setInfoHovered] = useState(false);
  const [themeButtonHovered, setThemeButtonHovered] = useState(false);

  return (
    <Root role="banner" onClick={onClick}>
      <FluidContainer>
        <Box>
          <LinkHome to="/">
            <Title>Bearly Bass</Title>
            <SubTitle>Handmade Bassloops</SubTitle>
          </LinkHome>

          <LinkInfo to="/info" onMouseEnter={() => setInfoHovered(true)} onMouseLeave={() => setInfoHovered(false)}>
            <InfoIcon symbol="info" size={16} $hovered={infoHovered} />
            <SrOnly>Info</SrOnly>
          </LinkInfo>
          <ThemeModeButton
            onClick={toggleColorScheme}
            onMouseEnter={() => setThemeButtonHovered(true)}
            onMouseLeave={() => setThemeButtonHovered(false)}
            aria-label={colorScheme === "light" ? "Switch to dark mode" : "Switch to light mode"}
          >
            {colorScheme === "light" ? (
              <MoonIcon symbol="moon" $hovered={themeButtonHovered} />
            ) : (
              <SunIcon $hovered={themeButtonHovered}>
                <circle cx="12" cy="12" r="5"></circle>
                <line x1="12" y1="1" x2="12" y2="3"></line>
                <line x1="12" y1="21" x2="12" y2="23"></line>
                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                <line x1="1" y1="12" x2="3" y2="12"></line>
                <line x1="21" y1="12" x2="23" y2="12"></line>
                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
              </SunIcon>
            )}
          </ThemeModeButton>
        </Box>
      </FluidContainer>
    </Root>
  );
}

export default Header;
